<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
      <PageHead :title="title" :head="head" />
      <Body>
        <div class="bg-white">
            <slot></slot>
        </div>
      </Body>
  </Html>
</template>

<script setup>
  const route = useRoute()
  const { t } = useI18n()
  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true
  })
  const title = computed(() => t('layouts.title', { title: t(route.meta.title ?? 'TBD') }))
</script>

<style scoped>

</style>